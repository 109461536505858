import ReactvPlatforms from '../lib/reactv-platforms/index';
import { getTracksInfo } from '../api/catalog';
import { getPlaybackStream, reportPlayback } from '../api/playback';
import { saveRadioStation } from '../api/playlists';
import { updatePodcastProgress } from '../api/podcast';
import { postV1APIRoute } from '../api/utils';
import { BASE_URL } from '../constants';
import { setPlayerSource, setPlayerStation } from './audioPlayer';
import { checkItemIsInLibrary } from './library';
import { addToast } from './toasts';
import { PAGINATION_PAGE_SIZE } from './view';
import { getLibraryCollectionTracks } from '../api/collection';
import { calculatePaginateProps } from '../lib/utils';
import axios from 'axios';

let tritonGuid;
let tritonPaused = null;
let tritonTimer;
let tritonTimerStarted;
let nextTritonType;

function tritonPause() {
  clearTimeout(tritonTimer);
  tritonPaused = Date.now();
}

function tritonResume() {
  tritonPaused = null;
  const delta = (Date.now() - tritonTimerStarted) / 1000;
  if (delta < 60) {
    tritonTimer = setTimeout(tritonPing, (60 - delta) * 1000);
  } else if (delta < 180) {
    tritonPing();
  } else {
    return false;
  }
  return true;
}

function tritonListener(sid) {
  return async function(dispatch, getState) {
    const {
      session: { profileId: vid }
    } = getState();
    const url = `https://lt110.tritondigital.com/lt?sid=${sid}&dev=${ReactvPlatforms.platform.getTritonDevVariable()}&vid=${vid}`;
    const result = await axios.get(url);
    tritonGuid = result.data.split(',')[1];
    tritonSchedule();
    console.log(`triton ${tritonGuid}`);
  };
}

async function tritonPing() {
  const url = `https://lt110.tritondigital.com/lt?guid=${tritonGuid}`;
  const result = await axios.get(url);
  tritonGuid = result.data.split(',')[1];
  tritonSchedule();
  console.log(`triton ${tritonGuid}`);
}

function tritonSchedule(time = 60000) {
  tritonTimer = setTimeout(tritonPing, time);
  tritonTimerStarted = Date.now();
}

const TRITON_CUSTOM_RADIO = 'TRITON_CUSTOM_RADIO';
const TRITON_INVALID = 'TRITON_INVALID';
const TRITON_TALK_SHOW = 'TRITON_TALK_SHOW';
function tritonType(type) {
  if (
    type === 'ALBUM' ||
    type === 'ARTIST' ||
    type === 'PLAYLIST' ||
    type === 'PODCAST_EPISODE'
  ) {
    return TRITON_CUSTOM_RADIO;
  }
  return TRITON_INVALID;
}

const TRITON_SID = {
  [TRITON_CUSTOM_RADIO]: 20731,
  [TRITON_TALK_SHOW]: 23049
};

function registerListen() {
  return async function(dispatch, getState) {
    const {
      session: { profileId, sessionId },
      station: { contentId }
    } = getState();
    const url = `${BASE_URL}/api/v1/liveRadio/${profileId}/${contentId}/registerListen`;
    const params = new URLSearchParams();
    params.append('profileId', profileId);
    params.append('sessionId', sessionId);
    params.append('ownerProfileId', profileId);
    params.append('liveRadioStationId', contentId);
    await postV1APIRoute(url, params);
    console.log('registerListen');
  };
}

function changeContent({
  contentId,
  contentType,
  userId,
  isOneSong,
  currentIndex,
  fullPlayerTitle,
  isTracksList,
  libraryId,
  viewId
}) {
  return async function(dispatch, getState) {
    const { audioPlayer, station } = getState();
    if (station.contentId !== undefined && station.contentType !== 'LIVE') {
      let timeToReport = audioPlayer.currentTrackTime;

      if (station.contentType === 'PODCAST_EPISODE') {
        timeToReport =
          audioPlayer.currentTrackTime -
          station.currentSong.content.secondsPlayed;
      }
      dispatch(report('STATIONCHANGE', timeToReport));
    }
    const prevTritonType = tritonType(station.contentType);
    nextTritonType = tritonType(contentType);
    if (prevTritonType !== nextTritonType) {
      clearTimeout(tritonTimer);
      tritonTimer = null;
      if (nextTritonType !== TRITON_INVALID) {
        dispatch(tritonListener(TRITON_SID[nextTritonType]));
      }
    }
    dispatch(
      setContent({
        contentId,
        contentType,
        userId,
        isOneSong,
        currentIndex,
        fullPlayerTitle,
        isTracksList,
        libraryId,
        viewId
      })
    );
    dispatch(setPlayerStation(contentId, contentType));
  };
}

function fetchTrack(songId, cancelToken) {
  return function(dispatch, getState) {
    const {
      station: { contentId, contentType, userId },
      session: { isPremiumUser }
    } = getState();
    const contentIds = [];

    const stationType =
      songId === undefined
        ? 'RADIO'
        : contentType === 'PLAYLIST'
          ? 'COLLECTION'
          : contentType === 'PODCAST_EPISODE'
            ? 'PODCAST'
            : contentType;
    let song = null;
    if (songId) {
      contentIds.push(songId);
    }

    let payload = {
      contentIds,
      stationType,
      limit: 1,
      stationId: contentId,
      playedFrom: 0
    };

    if (contentType === 'PLAYLIST') {
      if (!isPremiumUser) {
        payload.contentIds = undefined;
        payload.stationId = `${userId}::${contentId}`;
      }
    }

    return new Promise(resolve => {
      async function fetch() {
        const stream = await getPlaybackStream(payload, { cancelToken });
        if (stream && stream.data) {
          song = stream.data.items[0];
          if (!song || !song.content) {
            console.warn('Empty song data', songId);
            return;
          }
          if (songId !== undefined) {
            dispatch(stationCacheSong(song));
          }

          dispatch(
            setIsSkipAllowed({
              daySkipsRemaining: stream.data.skips.daySkipsRemaining,
              hourSkipsRemaining: stream.data.skips.hourSkipsRemaining
            })
          );

          resolve(song);
        } else {
          fetchRetry = setTimeout(fetch, 2000);
        }
      }
      fetch();
    });
  };
}

let fetchRetry;
function loadAndSetTrack(nextSongId) {
  return async function(dispatch, getState) {
    const song = await dispatch(fetchTrack(nextSongId));
    const nextId = song.content.id;
    dispatch(setNextSong(song, nextId));
  };
}

export function report(status, secondsPlayed) {
  console.log(status, secondsPlayed);
  return function(dispatch, getState) {
    const {
      station: { currentSong }
    } = getState();
    if (!currentSong) {
      console.error('Current song not found');
      return;
    }

    return reportPlayback({
      secondsPlayed,
      status,
      playedDate: Date.now(),
      reportPayload: currentSong.reportPayload
    })
      .then(({ data }) => {
        dispatch(
          setIsSkipAllowed({
            daySkipsRemaining: data.daySkipsRemaining,
            hourSkipsRemaining: data.hourSkipsRemaining
          })
        );
      })
      .catch(err => console.log(err));
  };
}

export function checkpoint() {
  return function(dispatch) {
    dispatch(report('REPORT_15', 15));
  };
}

export function done(fullStop = false) {
  return async function(dispatch, getState) {
    const {
      session: { isPremiumUser },
      audioPlayer: { currentTrackTime },
      station: { contentType, nextSongId, currentSong, isOneSong }
    } = getState();
    await dispatch(report('DONE', currentTrackTime));
    if (contentType === 'PODCAST_EPISODE') {
      await dispatch(setPodcastSecondsPlayed(currentTrackTime));
      dispatch(next());
    }
    if (isOneSong && currentSong.content.artistId) {
      dispatch(playArtist(currentSong.content.artistId));
      return;
    }

    if (fullStop === false) {
      if (!nextSongId) {
        if (contentType === 'ALBUM' && currentSong.content.artistId) {
          dispatch(playArtist(currentSong.content.artistId));
        }

        if (contentType === 'PLAYLIST' && !isPremiumUser) {
          tritonPause();
        }
      } else {
        dispatch(next());
      }
    } else {
      tritonPause();
    }
  };
}

export function pauseStation(value) {
  return function(dispatch, getState) {
    if (nextTritonType === TRITON_INVALID) return;
    if (value === true) {
      tritonPause();
    } else if (tritonPaused !== null) {
      if (tritonResume() === false) {
        dispatch(tritonListener(TRITON_SID[nextTritonType]));
      }
    }
  };
}

export function playNextTrack(isMiniPlayer) {
  return async function(dispatch, getState) {
    const { station } = getState();
    if (station.isSkipAllowed && station.isSkipAllowed.daySkipsRemaining <= 0) {
      dispatch(
        addToast({
          type: `${isMiniPlayer ? 'miniplayer' : 'fullplayer'}-daylimit`
        })
      );
      return;
    }

    if (
      station.isSkipAllowed &&
      station.isSkipAllowed.hourSkipsRemaining <= 0
    ) {
      dispatch(
        addToast({
          type: `${isMiniPlayer ? 'miniplayer' : 'fullplayer'}-hourlimit`
        })
      );
      return;
    }

    if (
      station.isOneSong &&
      station.contentType === 'ALBUM' &&
      station.currentSong.content.artistId
    ) {
      dispatch(playArtist(station.currentSong.content.artistId));
      return;
    }

    if (station.nextSongId !== null || station.contentType === 'ALBUM') {
      // next song exits or loop through album after last song
      dispatch(skip());
    }
  };
}

export function playPrevTrack() {
  return async function(dispatch, getState) {
    const { station, session } = getState();

    if (
      station.currentIndex > 0 &&
      ((station.contentType === 'PLAYLIST' && session.isPremiumUser) ||
        station.contentType === 'ALBUM')
    ) {
      dispatch(prev());
    }
  };
}

const CancelToken = axios.CancelToken;
let source;

export function next(trackIndex, isSkip) {
  return async function(dispatch, getState) {
    const {
      station,
      audioPlayer,
      session: { isPremiumUser },
      library
    } = getState();
    const {
      cache,
      currentIndex,
      nextSong,
      playlist,
      contentId,
      contentType,
      upperTitle,
      subtitle,
      playing
    } = station;
    if (playing === false || !nextSong) return;
    let nextIndex =
      trackIndex && trackIndex >= 0 ? trackIndex : currentIndex + 1;
    if (playlist && nextIndex >= playlist.length) nextIndex = 0;
    let track = nextSong;

    if (isSkip) {
      dispatch(report('SKIP', audioPlayer.currentTrackTime));
    }

    const {
      title,
      artistName,
      imageUrl,
      image,
      imagePath,
      albumName,
      startDate,
      secondsPlayed,
      duration
    } = track.content;

    dispatch(setPlayingSong(nextIndex, track, track.content.id));

    switch (contentType) {
      case 'ALBUM':
        if (contentId !== track.content.albumId) {
          dispatch(updateContentId(track.content.albumId));
          await checkItemIsInLibrary({
            contentType: 'ALBUM',
            contentId: track.content.albumId
          });
        }
        if (track.streamUrl === audioPlayer.currentStreamUrl) {
          dispatch(setPlayerSource({ currentStreamUrl: '' }));
        }
        dispatch(setPlayerSource({ currentStreamUrl: track.streamUrl }));
        dispatch(
          setPlayerLayout({
            title,
            subtitle: artistName,
            image: imagePath || imageUrl || image,
            upperTitle: albumName
          })
        );
        break;
      case 'PLAYLIST':
        dispatch(setPlayerSource({ currentStreamUrl: track.streamUrl }));
        dispatch(
          setPlayerLayout({
            title,
            subtitle: artistName,
            image: imagePath || imageUrl || image,
            upperTitle: upperTitle
          })
        );
        break;
      case 'PODCAST_EPISODE':
        dispatch(
          setPlayerSource({
            currentStreamUrl: track.streamUrl,
            currentTrackTime: duration === secondsPlayed ? 0 : secondsPlayed,
            currentTrackDuration: duration
          })
        );
        dispatch(
          setPlayerLayout({
            title,
            subtitle,
            image: station.image,
            upperTitle,
            secondsPlayed,
            date: startDate
          })
        );
        break;
      default:
        dispatch(setPlayerSource({ currentStreamUrl: track.streamUrl }));
        dispatch(
          setPlayerLayout({
            title,
            subtitle: artistName,
            image: imagePath || imageUrl || image
          })
        );
    }

    if (playlist !== null) {
      let nextSongIndex = nextIndex + 1;
      if (
        (contentType === 'ALBUM' ||
          (contentType === 'PLAYLIST' && isPremiumUser)) &&
        nextSongIndex >= playlist.length
      ) {
        nextSongIndex = 0;
      }
      const songId = playlist[nextSongIndex] && playlist[nextSongIndex].id;
      if (songId !== undefined) {
        if (
          songId in cache === false ||
          (contentType === 'PLAYLIST' && !isPremiumUser)
        ) {
          dispatch(loadAndSetTrack(songId));
        } else {
          dispatch(setNextSong(cache[songId], songId));
        }
      } else {
        dispatch(clearNextSong());
      }
    } else {
      dispatch(loadAndSetTrack());
    }

    if (station.libraryId === 'songs' && station.pagination) {
      const {
        overThreshold,
        isPageNotLoaded,
        currentPage
      } = calculatePaginateProps({
        fetchedCollectionPages: station.pagination,
        index: station.currentIndex,
        PAGINATION_PAGE_SIZE
      });

      if (overThreshold && isPageNotLoaded) {
        if (station.playlist.length < library[station.libraryId].length) {
          dispatch(setPlaylist(library[station.libraryId]));
        } else {
          // Not all collections use the pageKey method for pagination
          const nextPageKey =
            station.pagination &&
            station.pagination[currentPage] &&
            station.pagination[currentPage].nextPageKey;
          if (nextPageKey === false) {
            return;
          }
          await dispatch(
            loadStationCollectionPage(
              PAGINATION_PAGE_SIZE,
              currentPage + 1,
              station.playlist,
              station.pagination &&
                station.pagination[currentPage] &&
                station.pagination[currentPage].nextPageKey
            )
          );
        }
      }
    }
  };
}

export function playAlbum(album, trackIndex = 0) {
  return async function(dispatch, getState) {
    const { session } = getState();
    if (session.isPremiumUser !== true) return;
    const contentId = album.id || album.albumId;
    const contentType = 'ALBUM';
    dispatch(
      changeContent({
        contentId,
        contentType,
        fullPlayerTitle: album.name
      })
    );
    const { tracks } = album;
    dispatch(setPlaylist(tracks));
    await dispatch(loadAndSetTrack(tracks[trackIndex].id));
    dispatch(next(trackIndex));
    await checkItemIsInLibrary({
      contentType,
      contentId
    });
  };
}

const setLastPlayedStation = (data, contentType) => {
  let lastPlayedStation = localStorage.getItem('ade:ihr:last-played-station');
  const setData = JSON.stringify(data);
  if (
    (lastPlayedStation && lastPlayedStation !== setData) ||
    !lastPlayedStation
  ) {
    localStorage.setItem('ade:ihr:last-played-station', setData);
  }
};

const SET_IS_STATION_PAGE_LOADED = 'SET_IS_STATION_PAGE_LOADED';
export const setIsStationPageLoaded = (
  pageNumber,
  pageValue,
  nextPageKey,
  loaded
) => {
  return {
    type: SET_IS_STATION_PAGE_LOADED,
    paginationPage: {
      pageNumber,
      pageValue,
      nextPageKey,
      loaded
    }
  };
};

const SET_STATION_PAGES = 'SET_STATION_PAGES';
export const setStationPages = pagination => {
  return {
    type: SET_STATION_PAGES,
    pagination
  };
};

export const loadStationCollectionPage = (
  pageSize,
  pageNumber,
  menuItems,
  nextPageKey
) => {
  return async function(dispatch, getState) {
    dispatch(setIsStationPageLoaded(pageNumber, true)); // immediately set to prevent repeated calls
    getLibraryCollectionTracks(
      PAGINATION_PAGE_SIZE,
      pageNumber,
      nextPageKey,
      true,
      true
    )
      .then(data => {
        if (!data) {
          console.warn('No page data received for', pageNumber);
          return;
        }
        let loaded = menuItems.length;
        data.forEach(item => {
          menuItems.push({ ...item, startFrom: loaded + item.startFrom });
        });
        dispatch(setPlaylist(menuItems));
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export function playArtist(sourceId) {
  return async function(dispatch, getState) {
    const { session } = getState();
    const contentType = 'ARTIST';

    if (
      session.userProfile.preferences &&
      session.userProfile.preferences['play.on.startup'] === '1'
    ) {
      const data = { data: sourceId, type: contentType };
      setLastPlayedStation(data, contentType);
    }
    const playlistData = await saveRadioStation(contentType, sourceId, true);
    if (!playlistData || !playlistData.id) {
      console.error('Missing playlist data for', sourceId);
      return;
    }
    const contentId = playlistData.id;
    dispatch(
      changeContent({
        contentId,
        contentType,
        fullPlayerTitle: playlistData.name
      })
    );
    dispatch(setSource(sourceId));
    await dispatch(loadAndSetTrack());
    dispatch(next());
    await checkItemIsInLibrary({
      contentType,
      sourceId
    });
  };
}

export function playFavorites(favoriteId) {
  return async function(dispatch, getState) {
    const { station } = getState();
    if (station.contentType === 'FAVORITES') {
      // Prevent re-starting favorites radio as method of avoiding skip limit
      return;
    }
    const contentType = 'FAVORITES';
    dispatch(changeContent({ contentId: favoriteId, contentType }));
    dispatch(setSource(favoriteId));
    await dispatch(loadAndSetTrack());
    dispatch(next());
  };
}

export function playPodcastEpisode(episode) {
  return async function(dispatch, getState) {
    const { session } = getState();
    const contentId = episode.id;
    const contentType = 'PODCAST_EPISODE';

    if (
      session.userProfile.preferences &&
      session.userProfile.preferences['play.on.startup'] === '1'
    ) {
      const data = {
        data: {
          id: episode.id,
          podcastTitle: episode.podcastTitle,
          imageUrl: episode.imageUrl
        },
        type: contentType
      };
      setLastPlayedStation(data, contentType);
    }

    dispatch(
      changeContent({
        contentId,
        contentType,
        fullPlayerTitle: episode.podcastTitle
      })
    );
    const playlist = episode.playlist || [episode];
    const episodeIndex = playlist.findIndex(item => {
      if (item.id === episode.id) return true;
      return false;
    });
    dispatch(setPlaylist(playlist));
    await dispatch(loadAndSetTrack(playlist[episodeIndex].id));
    dispatch(
      setPlayerLayout({
        upperTitle: episode.podcastTitle,
        subtitle: episode.podcastTitle,
        image: episode.imageUrl
      })
    );
    dispatch(next(episodeIndex));
    dispatch(report('START', 0));
    await checkItemIsInLibrary({
      contentType: 'PODCAST',
      podcastId: episode.podcastId
    });
  };
}

export function playPlaylist(playlist, trackIndex, contentType = 'PLAYLIST') {
  return async function(dispatch, getState) {
    const { station, session } = getState();
    const contentId = playlist.id;

    if (
      session.userProfile.preferences &&
      session.userProfile.preferences['play.on.startup'] === '1' &&
      !playlist.disablePlayOnStartup
    ) {
      const data = {
        data: {
          id: playlist.id,
          userId: playlist.userId
        },
        type: contentType
      };
      setLastPlayedStation(data, contentType);
    }
    const userId = playlist.userId;
    if (!playlist.tracks || !playlist.tracks.length) {
      console.warn('Received playlist without tracks', playlist, trackIndex);
      return;
    }
    const tracks = playlist.tracks;
    // const tracks = playlist.tracks.map(i => i.id);
    if (contentId !== station.contentId) {
      // If new playlist, set it
      dispatch(
        changeContent({
          contentId,
          contentType,
          userId,
          currentIndex: trackIndex,
          fullPlayerTitle: playlist.name
        })
      );
      dispatch(setPlayerLayout({ upperTitle: playlist.name }));
      dispatch(setPlaylist(tracks));
    }
    await dispatch(loadAndSetTrack(tracks[trackIndex].id));
    dispatch(next(trackIndex));
    if (contentType !== 'ALBUM') {
      await checkItemIsInLibrary({
        contentType,
        userId: userId,
        playlistId: contentId
      });
    }
  };
}

export function playTracks({
  tracks,
  startFrom,
  contentId,
  libraryId,
  viewId,
  updateStationPagination
}) {
  return async function(dispatch, getState) {
    if (updateStationPagination) {
      dispatch(setStationPages(updateStationPagination));
    }
    const userId = getState().session.profileId;
    dispatch(
      changeContent({
        contentId,
        contentType: 'ALBUM',
        userId,
        currentIndex: startFrom,
        isTracksList: true,
        libraryId,
        viewId
      })
    );
    dispatch(setPlaylist(tracks));
    await dispatch(loadAndSetTrack(tracks[startFrom].id));
    dispatch(next(startFrom, true));
    await checkItemIsInLibrary({
      contentType: 'ALBUM',
      userId: userId,
      contentId
    });
  };
}

export function playRadio({
  stationId,
  image,
  title,
  streamUrl,
  subtitle,
  callLetters,
  liveRadioCurrentSong,
  force
}) {
  return async function(dispatch, getState) {
    const { session } = getState();
    const contentId = stationId;
    const contentType = 'LIVE';

    if (
      session.userProfile.preferences &&
      session.userProfile.preferences['play.on.startup'] === '1'
    ) {
      const data = {
        data: {
          stationId,
          image,
          title,
          streamUrl,
          subtitle
        },
        type: contentType
      };
      setLastPlayedStation(data, contentType);
    }
    dispatch(changeContent({ contentId, contentType }));
    dispatch(setPlayerLayout({ image, title, subtitle, callLetters }));
    dispatch(setLiveRadioCurrentSong(liveRadioCurrentSong));
    dispatch(registerListen());
    dispatch(setPlayerSource({ currentStreamUrl: streamUrl, force }));
    await checkItemIsInLibrary({
      contentType,
      contentId
    });
  };
}

export function playSong(songId) {
  return async function(dispatch, getState) {
    const {
      session: { isPremiumUser }
    } = getState();
    if (!isPremiumUser) return;
    const [song] = await getTracksInfo(songId);
    const contentId = song.albumId;
    const contentType = 'ALBUM';
    dispatch(changeContent({ contentId, contentType, isOneSong: true }));
    const playlist = [song];
    dispatch(setPlaylist(playlist));
    await dispatch(loadAndSetTrack(playlist[0].id));
    dispatch(next());
  };
}

export function prev() {
  return async function(dispatch, getState) {
    const {
      station: {
        cache,
        currentIndex,
        currentSong,
        currentSongId,
        playlist,
        contentType,
        contentId,
        playing,
        libraryId
      },
      audioPlayer: { upperTitle, currentStreamUrl },
      session: { isPremiumUser }
    } = getState();
    if (playing === false) return;
    let track;
    let prevIndex = currentIndex - 1;
    if (
      (contentType === 'ALBUM' ||
        (contentType === 'PLAYLIST' && isPremiumUser)) &&
      playlist &&
      prevIndex < 0
    ) {
      prevIndex =
        libraryId === 'songs' ? playlist.length - 3 : playlist.length - 1;
    }

    const prevPlaylistSong = playlist[prevIndex];

    if (libraryId !== 'songs') {
      dispatch(setNextSong(currentSong, currentSongId));
    } else {
      let song;
      if (cache[playlist[prevIndex + 1].id]) {
        song = cache[playlist[prevIndex + 1].id];
      } else {
        song = await dispatch(
          fetchTrack(playlist[prevIndex + 1].id, source && source.token)
        );
      }
      dispatch(setNextSong(song, playlist[prevIndex + 1].id));
    }

    if (cache[prevPlaylistSong.id]) {
      dispatch(
        setPlayingSong(
          prevIndex,
          cache[prevPlaylistSong.id],
          playlist[prevIndex].id
        )
      );
      track = cache[prevPlaylistSong.id];
    } else {
      if (source) {
        source.cancel('Canceled because song is already skipped');
      }
      source = CancelToken.source();

      const song = await dispatch(
        fetchTrack(prevPlaylistSong.id, source.token)
      );
      dispatch(setPlayingSong(prevIndex, song, prevPlaylistSong.id));
      if (song.streamUrl) {
        track = song;
      } else {
        return;
      }
    }

    const {
      title,
      artistName,
      imageUrl,
      image,
      imagePath,
      albumName
    } = track.content;

    switch (contentType) {
      case 'ALBUM':
        if (contentId !== track.content.albumId) {
          dispatch(updateContentId(track.content.albumId));
          await checkItemIsInLibrary({
            contentType: 'ALBUM',
            contentId: track.content.albumId
          });
        }
        if (track.streamUrl === currentStreamUrl) {
          dispatch(setPlayerSource({ currentStreamUrl: '' }));
        }
        dispatch(setPlayerSource({ currentStreamUrl: track.streamUrl }));
        dispatch(
          setPlayerLayout({
            title,
            subtitle: artistName,
            image: imagePath || imageUrl || image,
            upperTitle: albumName
          })
        );
        break;
      case 'PLAYLIST':
        dispatch(setPlayerSource({ currentStreamUrl: track.streamUrl }));
        dispatch(
          setPlayerLayout({
            title,
            subtitle: artistName,
            image: imagePath || imageUrl || image,
            upperTitle: upperTitle
          })
        );
        break;
      default:
        dispatch(setPlayerSource({ currentStreamUrl: track.streamUrl }));
        dispatch(
          setPlayerLayout({
            title,
            subtitle: artistName,
            image: imagePath || imageUrl || image
          })
        );
    }
  };
}

export function skip() {
  return function(dispatch, getState) {
    dispatch(next(null, true));
  };
}

const SET_PODCAST_SECONDS_PLAYED = 'SET_PODCAST_SECONDS_PLAYED';
export function setPodcastSecondsPlayed(secondsPlayed) {
  return async function(dispatch, getState) {
    const { audioPlayer, station } = getState();
    await updatePodcastProgress(
      station.currentSong && station.currentSong.content.podcastId,
      station.currentSong && station.currentSong.content.id,
      secondsPlayed || audioPlayer.currentTrackTime
    );
    const type = SET_PODCAST_SECONDS_PLAYED;
    const payload = {
      secondsPlayed: secondsPlayed || audioPlayer.currentTrackTime
    };
    dispatch({ type, payload });
  };
}

const SET_LIVE_CURRENT_SONG = 'SET_LIVE_CURRENT_SONG';
export function setLiveRadioCurrentSong(liveRadioCurrentSong) {
  return function(dispatch, getState) {
    const type = SET_LIVE_CURRENT_SONG;
    const payload = {
      liveRadioCurrentSong
    };
    dispatch({ type, payload });
  };
}

const CLEAR_STATION_DATA = 'CLEAR_STATION_DATA';
export function clearStationData() {
  const type = CLEAR_STATION_DATA;
  return { type };
}

const CLEAR_NEXT_SONG = 'CLEAR_NEXT_SONG';
function clearNextSong() {
  const type = CLEAR_NEXT_SONG;
  const payload = {
    nextSong: null,
    nextSongId: null
  };
  return { type, payload };
}

const STATION_CACHE_SONG = 'STATION_CACHE_SONG';
function stationCacheSong(song) {
  const type = STATION_CACHE_SONG;
  return { type, song };
}

const SET_CONTENT = 'SET_CONTENT';
function setContent({
  contentId,
  contentType,
  userId,
  isOneSong,
  currentIndex,
  fullPlayerTitle,
  isTracksList,
  libraryId,
  viewId
}) {
  clearTimeout(fetchRetry);
  const type = SET_CONTENT;
  const payload = {
    cache: {},
    contentId,
    contentType,
    userId,
    isOneSong,
    fullPlayerTitle,
    isTracksList,
    libraryId,
    viewId,
    currentIndex: currentIndex ? currentIndex - 1 : -1,
    currentSong: null,
    currentSongId: null,
    nextSong: null,
    nextSongId: null,
    playlist: null,
    sourceId: null
  };
  return { type, payload };
}

const SET_NEXT_SONG = 'SET_NEXT_SONG';
function setNextSong(nextSong, nextSongId) {
  const type = SET_NEXT_SONG;
  const payload = { nextSong, nextSongId };
  return { type, payload };
}

const SET_PLAYING_SONG = 'SET_PLAYING_SONG';
function setPlayingSong(currentIndex, currentSong, currentSongId) {
  const type = SET_PLAYING_SONG;
  const payload = { currentIndex, currentSong, currentSongId };
  return { type, payload };
}

const SET_PLAYLIST = 'SET_PLAYLIST';
export function setPlaylist(playlist) {
  const type = SET_PLAYLIST;
  console.log('setPlaylist', playlist);
  const payload = { playlist };
  return { type, payload };
}

const SET_SOURCE = 'SET_SOURCE';
function setSource(sourceId) {
  const type = SET_SOURCE;
  const payload = { sourceId };
  return { type, payload };
}

const SET_IS_SKIP_ALLOWED = 'SET_IS_SKIP_ALLOWED';
function setIsSkipAllowed(isSkipAllowed) {
  const type = SET_IS_SKIP_ALLOWED;
  const payload = { isSkipAllowed };
  return { type, payload };
}

const UPDATE_CONTENT_ID = 'UPDATE_CONTENT_ID';
export function updateContentId(contentId) {
  const type = UPDATE_CONTENT_ID;
  const payload = { contentId };
  return { type, payload };
}

const STATION_PLAYING = 'STATION_PLAYING';
export function stationPlaying() {
  const type = STATION_PLAYING;
  return { type };
}

const SET_PLAYER_LAYOUT = 'SET_PLAYER_LAYOUT';
function setPlayerLayout({
  image,
  title,
  upperTitle,
  subtitle,
  date,
  secondsPlayed,
  callLetters
}) {
  const type = SET_PLAYER_LAYOUT;
  const payload = {
    image,
    title,
    upperTitle: upperTitle || subtitle,
    subtitle,
    date,
    secondsPlayed,
    callLetters
  };
  return { type, payload };
}

export default function(state = {}, action) {
  switch (action.type) {
    case CLEAR_STATION_DATA:
      return {};
    case CLEAR_NEXT_SONG:
      return { ...state, ...action.payload };
    case UPDATE_CONTENT_ID:
      return { ...state, ...action.payload };
    case SET_CONTENT:
      return { ...state, ...action.payload, liveRadioCurrentSong: null };
    case SET_NEXT_SONG:
      return { ...state, ...action.payload };
    case SET_PLAYING_SONG:
      return { ...state, ...action.payload, playing: false };
    case SET_PLAYLIST:
      return { ...state, ...action.payload };
    case SET_PODCAST_SECONDS_PLAYED:
      return { ...state, ...action.payload };
    case SET_SOURCE:
      return { ...state, ...action.payload };
    case SET_IS_SKIP_ALLOWED:
      return { ...state, ...action.payload };
    case SET_PLAYER_LAYOUT:
      return { ...state, ...action.payload };
    case SET_LIVE_CURRENT_SONG:
      return {
        ...state,
        liveRadioCurrentSong: action.payload.liveRadioCurrentSong
      };
    case STATION_CACHE_SONG:
      if (!action.song || !action.song.content) {
        return { ...state };
      }
      return {
        ...state,
        cache: Object.assign({}, state.cache, {
          [action.song.content.id]: action.song
        })
      };
    case STATION_PLAYING:
      return {
        ...state,
        playing: true
      };
    case SET_IS_STATION_PAGE_LOADED:
      const existingPages = state.pagination;
      let newPaginationState;
      if (existingPages) {
        newPaginationState = existingPages;
      } else {
        newPaginationState = {};
      }
      newPaginationState[action.paginationPage.pageNumber] = {
        value: action.paginationPage.pageValue,
        nextPageKey: action.paginationPage.nextPageKey,
        loaded: action.paginationPage.loaded,
        isPlayerPagination: action.paginationPage.isPlayerPagination
      };
      return {
        ...state,
        pagination: newPaginationState
      };
    case SET_STATION_PAGES:
      return {
        ...state,
        pagination: action.pagination
      };
    default:
      return state;
  }
}
